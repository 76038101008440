import React from "react";
import ReactMarkdown from "react-markdown";
import { useParams, Link } from "react-router-dom";

import { formatDate, formatContractTime } from "../helpers";
import "./JobDetail.scss";

function JobDetail({ jobs }) {
  const params = useParams();
  const jobId = params.jobId;
  const job = jobs.find((job) => job.id === jobId);

  if (job != undefined) {
    console.log(job.description);
    return (
      <React.Fragment>
        <aside className="companyInfo">
          <img
            className="company__logo"
            src={"https://avatar.tobi.sh/" + job.id}
            alt="company logo"
          />
          <div className="companyUrl">
            <p className="detail__company">{job.company.display_name}</p>
            <p className="detail__url">Company site n/a</p>
          </div>

          <a className="button button--apply" href={job.redirect_url}>
            Apply Now
          </a>
        </aside>

        <section className="jobDetails">
          <span className="detail__created">{formatDate(job.created)}</span>
          <span> &bull; </span>
          <span className="detail__contractTime">
            {formatContractTime(job.contract_time)}
          </span>
          <h2 className="detail__title">{job.title}</h2>
          <p className="detail__location">{job.location.display_name}</p>
          <ReactMarkdown
            className="detail__description"
            children={job.description}
          />
          {/* <p className="detail__description">{job.description}</p> */}
          <Link to="/" className="button button--back">
            Back to results
          </Link>
        </section>
      </React.Fragment>
    );
  }
  return null;
}

export default JobDetail;
