import React, { Children } from "react";
import "./Checkbox.scss";

function Checkbox({ labelText, onChange }) {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  // render
  return (
    <div className="modal__checkbox__container">
      <input
        className="modal__checkbox"
        type="checkbox"
        id="FulltimeCheckbox"
        onChange={handleChange}
      />
      <label htmlFor="FulltimeCheckbox">{labelText}</label>
    </div>
  );
}

export default Checkbox;
