import React from "react";
import "./Header.scss";
import ThemeToggle from "./ThemeToggle";

function header() {
  return (
    <React.Fragment>
      <header>
        <h1 className="title">devjobs</h1>
        <ThemeToggle />
      </header>
    </React.Fragment>
  );
}

export default header;
