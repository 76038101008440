import React from "react";
import { Link } from "react-router-dom";
import { formatDate, formatContractTime } from "../helpers";
import "./JobCard.scss";

function JobCard(props) {
  if (props.skeleton === true) {
    return (
      <li className="jobCard">
        <div className="jobCard__logo skeleton"></div>
        <div className="jobCard__created skeleton"></div>
        <div className="jobCard__title skeleton"></div>
        <div className="jobCard__company skeleton"></div>
        <div className="jobCard__location skeleton"></div>
      </li>
    );
  }
  return (
    <li className="jobCard">
      <Link to={"/job/" + props.data.id} className="router__link">
        <img
          className="jobCard__logo"
          src={"https://avatar.tobi.sh/" + props.data.id}
          alt="company logo"
        />

        <span className="jobCard__created">
          {formatDate(props.data.created)}
        </span>
        <span> &bull; </span>
        <span className="jobCard__contractTime">
          {formatContractTime(props.data.contract_time)}
        </span>

        <h2 className="jobCard__title">{props.data.title}</h2>
        <p className="jobCard__company">{props.data.company.display_name}</p>
        <p className="jobCard__location">{props.data.location.display_name}</p>
      </Link>
    </li>
  );
}

export default JobCard;
