import React from "react";
import "./Button.scss";

function Button({ type, modal, onClick }) {
  if (type == "submit") {
    if (modal === true) {
      return (
        <button
          type="submit"
          className="button button--searchModal"
          aria-label="search button"
          onClick={onClick}
          form="form"
        >
          Search
        </button>
      );
    }
    return (
      <button
        type="submit"
        className="button button--search"
        aria-label="search button"
      />
    );
  } else if (type == "more") {
    return (
      <button
        type="button"
        onClick={onClick}
        className="button button--more"
        aria-label="more filters button"
      />
    );
  }
}

export default Button;
