import React from "react";
import ReactDom from "react-dom";
import QueryField from "./QueryField";
import Button from "./Button";
import Checkbox from "./Checkbox";
import "./FiltersModal.scss";

function ModalFilters({
  overlayOnClick,
  searchOnClick,
  locationOnChange,
  fulltimeOnChange,
  open,
}) {
  document.addEventListener("keyup", function (event) {
    if (event.key === "Escape") overlayOnClick();
  });

  if (!open) return null;
  return ReactDom.createPortal(
    <React.Fragment>
      <div className="modalOverlay" onClick={overlayOnClick}></div>

      <div className="modalFilters">
        <QueryField onChange={locationOnChange} modal={true} />

        <Checkbox labelText="Full Time Only" onChange={fulltimeOnChange} />

        <Button type="submit" modal={true} onClick={searchOnClick} />
      </div>
    </React.Fragment>,
    document.getElementById("portal")
  );
}

export default ModalFilters;
