import React from "react";
import "./QueryField.scss";

function FilterInput({ modal, onChange }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  // rendering
  if (modal === true) {
    return (
      <input
        className="modal"
        type="text"
        placeholder={"Filter by location..."}
        onChange={handleChange}
      />
    );
  }
  return (
    <input
      className="filter"
      type="text"
      placeholder={"Filter by title, company, job..."}
      onChange={handleChange}
    />
  );
}

export default FilterInput;
