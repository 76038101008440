export function formatDate(date) {
  const rtf = new Intl.RelativeTimeFormat("en", {
    numeric: "always",
    style: "long",
  });
  const units = {
    year: 52 * 7 * 24 * 60 * 60 * 1000,
    month: (52 / 12) * 7 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };

  const timeElapsed = new Date(date) - Date.now();
  for (const u in units) {
    if (Math.abs(timeElapsed) > units[u] || u === "second") {
      const amount = Math.round(timeElapsed / units[u]);
      return rtf.format(amount, u);
    }
  }
}

export function formatContractTime(data) {
  if (data == null) return "n/a";
  return data.replace("_", " ");
}
