import React from "react";
import JobCard from "./JobCard";
import "./JobCardsContainer.scss";

function JobCards(props) {
  return (
    <ul className="jobCards" aria-label="job card">
      {props.dataReturned
        ? props.jobs
            .slice(0, props.jobsShowing)
            .map((job) => <JobCard data={job} key={job.id} />)
        : [...Array(12)].map((job, index) => (
            <JobCard key={index} skeleton={true} />
          ))}
    </ul>
  );
}

export default JobCards;
