import React, { Component } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Header from "./Header";
import FilterBar from "./FilterBar";
import JobCardsContainer from "./JobCardsContainer";
import JobDetail from "./JobDetail";
import "./App.scss";

class App extends Component {
  state = {
    jobs: [],
    dataReturned: false,
    jobsShowing: 12,
  };

  performSearch = (query = "", location = "", fullTimeOnly = false) => {
    this.setState({ jobsShowing: 12, dataReturned: false });
    const appID = "823808ec";
    const appKey = "a42da726026a174c6a5cf34ab34833b4";
    const fullTime = fullTimeOnly ? 1 : 0;

    // replace spaces in query string with url encoding
    if (query != "") {
      query = query.replace(" ", "%20");
    }
    // replace spaces in location string with url encoding
    if (location != "") {
      location = location.replace(" ", "%20");
    }
    // api endpoint
    let url = `https://api.adzuna.com/v1/api/jobs/gb/search/1?app_id=${appID}&app_key=${appKey}&results_per_page=100&what=${query}&where=${location}&full_time=${fullTime}&content-type=application/json`;

    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        this.setState({ jobs: data.results, dataReturned: true });
      })
      .catch((error) => console.log(error));
  };

  loadMore = () => {
    console.log(this.state.jobsShowing);
    if (this.state.jobsShowing < this.state.jobs.length)
      this.setState({ jobsShowing: this.state.jobsShowing + 12 });
  };

  componentDidMount() {
    this.performSearch("reactjs");
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <FilterBar
                    onSearch={this.performSearch}
                    ref={this.state.childRef}
                  />
                  <JobCardsContainer
                    jobs={this.state.jobs}
                    dataReturned={this.state.dataReturned}
                    jobsShowing={this.state.jobsShowing}
                  />
                  <button
                    className="button button--moreJobs"
                    onClick={this.loadMore}
                  >
                    Load More
                  </button>
                </>
              }
            />
            <Route
              exact
              path="/job/:jobId"
              element={<JobDetail jobs={this.state.jobs} />}
            />
          </Routes>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
