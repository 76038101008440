import React, { Component } from "react";
import "./ThemeToggle.scss";

class ThemeToggle extends Component {
  state = {
    theme: localStorage.getItem("darkMode"),
    isChecked: "",
  };

  enableDarkMode = () => {
    document.body.classList.add("dark-mode");
    localStorage.setItem("darkMode", "enabled");
    this.setState({ theme: localStorage.getItem("darkMode") });
  };

  disableDarkMode = () => {
    document.body.classList.remove("dark-mode");
    localStorage.setItem("darkMode", null);
    this.setState({ theme: localStorage.getItem("darkMode") });
  };

  switchTheme = () => {
    if (this.state.theme === "enabled") {
      this.disableDarkMode();
    } else {
      this.enableDarkMode();
    }
  };

  componentDidMount() {
    if (this.state.theme === "enabled") {
      this.enableDarkMode();
    }
  }

  render() {
    let { theme, isChecked } = this.state;
    if (theme === "enabled") {
      isChecked = true;
    }

    return (
      <React.Fragment>
        <label className="toggle" htmlFor="myToggle">
          <input
            className="toggle__input"
            id="myToggle"
            type="checkbox"
            defaultChecked={isChecked}
            onClick={this.switchTheme}
          />
          <div className="toggle__fill"></div>
        </label>
      </React.Fragment>
    );
  }
}

export default ThemeToggle;
