import React, { Component } from "react";
import QueryField from "./QueryField";
import Button from "./Button";
import FiltersModal from "./FiltersModal";
import "./FilterBar.scss";

class FilterSection extends Component {
  state = {
    searchText: "",
    locationText: "",
    ftOnly: false,
    filterModalOpen: false,
  };

  searchInputOnChange = (searchValue) => {
    this.setState({ searchText: searchValue });
  };

  locationInputOnChange = (locationValue) => {
    this.setState({ locationText: locationValue });
  };

  fulltimeInputOnChange = (boolean) => {
    this.setState({ ftOnly: boolean });
  };

  openModal = () => {
    this.setState({ filterModalOpen: true });
  };

  closeModal = () => {
    this.setState({ filterModalOpen: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { searchText, locationText, ftOnly } = this.state;

    if ((this.state.searchText = "")) return;
    if (this.state.filterModalOpen === true) {
      this.props.onSearch(searchText, locationText, ftOnly);
      this.closeModal();
      e.currentTarget.form.reset();
    } else {
      this.props.onSearch(searchText);
      e.currentTarget.reset();
    }
  };

  render() {
    return (
      <form className="filters" onSubmit={this.handleSubmit} id="form">
        <QueryField onChange={this.searchInputOnChange} />
        <Button type="more" onClick={this.openModal} />
        <Button type="submit" />

        <FiltersModal
          open={this.state.filterModalOpen}
          overlayOnClick={this.closeModal}
          searchOnClick={this.handleSubmit}
          locationOnChange={this.locationInputOnChange}
          fulltimeOnChange={this.fulltimeInputOnChange}
        />
      </form>
    );
  }
}

export default FilterSection;
